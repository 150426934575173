export const INVALIDATION_REGISTRY = 'INVALIDATION_REGISTRY';

export const APP_PURCHASES = 'APP_PURCHASES';

export const APPS = 'APPS';

export const ACCOUNT = 'ACCOUNT';

export const ACCOUNT_CONVERSIONS = 'ACCOUNT_CONVERSIONS';

export const ANNOUNCEMENTS = 'ANNOUNCEMENTS';

export const BLOG_POST_AUTOMATION = 'BLOG_POST_AUTOMATION';
export const IS_BLOG_POST_ANNOUNCEMENT = 'IS_BLOG_POST_ANNOUNCEMENT';
export const BLOGS = 'BLOGS';
export const BLOG_ARTICLE = 'BLOG_ARTICLE';
export const BLOG_ARTICLES = 'BLOG_ARTICLES';

export const BURST_CATEGORIES = 'BURST_CATEGORIES';
export const BURST_CATEGORY_PHOTOS = 'BURST_CATEGORY_PHOTOS';
export const BURST_SEARCH = 'BURST_SEARCH';

export const CONTENT_FILE_IMAGES = 'CONTENT_FILE_IMAGES';
export const UPLOADED_IMAGES = 'UPLOADED_IMAGES';

export const CAMPAIGNS = 'CAMPAIGNS';
export const CAMPAIGN_GROUPS = 'CAMPAIGN_GROUPS';
export const ALL_CAMPAIGN_GROUPS = 'ALL_CAMPAIGN_GROUPS'; // These never contain campaigns.
export const RECENTLY_SENT_CAMPAIGN_GROUPS = 'RECENTLY_SENT_CAMPAIGN_GROUPS';
export const INSTALLABLE_CAMPAIGN_GROUPS = 'INSTALLABLE_CAMPAIGN_GROUPS';
export const CAMPAIGN_GROUP_STATS = 'CAMPAIGN_GROUP_STATS';
export const CAMPAIGN_PREVIEWS = 'CAMPAIGN_PREVIEWS';
export const CAMPAIGN_SEARCH = 'CAMPAIGN_SEARCH';
export const CAMPAIGN_RECIPIENTS = 'CAMPAIGN_RECIPIENTS';
export const CAMPAIGN_RECIPIENT_SEARCH = 'CAMPAIGN_RECIPIENT_SEARCH';
export const FOLLOWUP_CAMPAIGNS = 'FOLLOWUP_CAMPAIGNS';
export const WARMING_CAMPAIGN = 'WARMING_CAMPAIGN';

export const DISCOUNT_CODES_BY_ID = 'DISCOUNT_CODES_BY_ID';
export const DISCOUNT_CODES_BY_VALUE = 'DISCOUNT_CODES_BY_VALUE';
export const PRICE_RULES = 'PRICE_RULES';

export const EMAIL_TEMPLATES = 'EMAIL_TEMPLATES';
export const EMAIL_TEMPLATE_PREVIEWS = 'EMAIL_TEMPLATE_PREVIEWS';

export const EMAIL_EVENT_ACTIVITIES = 'EMAIL_EVENT_ACTIVITIES';

export const KLAVIYO_ACCOUNT = 'KLAVIYO_ACCOUNT';
export const KLAVIYO_LISTS = 'KLAVIYO_LISTS';
export const KLAVIYO_SYNC_COMPLETE = 'KLAVIYO_SYNC_COMPLETE';
export const KLAVIYO_SYNC_SCHEDULED = 'KLAVIYO_SYNC_SCHEDULED';

export const MARKETABLE_EVENT_STATS = 'MARKETABLE_EVENT_STATS';

export const MARKETING_ACTIVITY_LINK = 'MARKETING_ACTIVITY_LINK';
export const MARKETING_ACTIVITY_FLOW_EDITOR_URL = 'MARKETING_ACTIVITY_FLOW_EDITOR_URL';

export const MAILCHIMP_LISTS = 'MAILCHIMP_LISTS';

export const ONBOARDING_STATUS = 'ONBOARDING_STATUS';

export const PRODUCTS = 'PRODUCTS';
export const PRODUCT_COLLECTIONS = 'PRODUCT_COLLECTIONS';

export const PROGRAMS = 'PROGRAMS';
export const PROGRAM_STATE_SUMMARY = 'PROGRAM_STATE_SUMMARY';
export const PROGRAM_MESSAGE_EVENT_STATS = 'PROGRAM_MESSAGE_EVENT_STATS';
export const PROGRAM_MESSAGE = 'PROGRAM_MESSAGE';
export const PROGRAM_MESSAGES = 'PROGRAM_MESSAGES';
export const CAN_CONFIGURE_TRANSACTIONAL_SENDING = 'CAN_CONFIGURE_TRANSACTIONAL_SENDING';

export const AUTOMATION_FLOW_ACTIONS = 'AUTOMATION_FLOW_ACTIONS';

export const REUSABLE_SECTIONS = 'REUSABLE_SECTIONS';

export const STORE_TEMPLATES = 'STORE_TEMPLATES';
export const STORE_CAMPAIGN_GROUPS = 'STORE_CAMPAIGN_GROUPS';

export const SUBSCRIBER_COUNT = 'SUBSCRIBER_COUNT';

export const FACEBOOK_PAGES = 'FACEBOOK_PAGES';
export const FACEBOOK_ACCESS_EXPIRES_AT = 'FACEBOOK_ACCESS_EXPIRES_AT';

export const CUSTOMER = 'CUSTOMER';
export const CUSTOMER_GROWTHS = 'CUSTOMER_GROWTHS';
export const CUSTOMER_TIMELINE = 'CUSTOMER_TIMELINE';
export const CUSTOMER_SEND_STATS = 'CUSTOMER_SEND_STATS';
export const RECENT_CUSTOMERS = 'RECENT_CUSTOMERS';
export const CUSTOMER_TAGS_SEARCH = 'CUSTOMER_TAGS_SEARCH';
export const CUSTOMERS_SEARCH = 'CUSTOMERS_SEARCH';

export const INSTAGRAM_PHOTOS = 'INSTAGRAM_PHOTOS';
export const INSTAGRAM_LOGIN_URL = 'INSTAGRAM_LOGIN_URL';
export const INSTAGRAM_USER = 'INSTAGRAM_USER';

export const VIDEO_THUMBNAIL = 'VIDEO_THUMBNAIL';

export const ACTIVE_CHARGE = 'ACTIVE_CHARGE';

export const SIGNATURE = 'SIGNATURE';

export const SCREENSHOT = 'SCREENSHOT';

export const CAMPAIGN_TYPE_EMAIL_TEMPLATES = 'CAMPAIGN_TYPE_EMAIL_TEMPLATES';

export const TRANSLATIONS = 'TRANSLATIONS';
export const ACCOUNT_LANGUAGE = 'ACCOUNT_LANGUAGE';
export const ACCOUNT_LANGUAGES = 'ACCOUNT_LANGUAGES';

export const SHOPIFY_LINKS = 'SHOPIFY_LINKS';
export const SHOPIFY_LINK_SEARCH = 'SHOPIFY_LINK_SEARCH';

export const BRANDING_PREVIEW = 'BRANDING_PREVIEW';

export const SENDING_DOMAIN = 'SENDING_DOMAIN';

export const BACK_IN_STOCK_REGISTRANTS = 'BACK_IN_STOCK_REGISTRANTS';
export const BACK_IN_STOCK_REGISTRATIONS = 'BACK_IN_STOCK_REGISTRATIONS';

export const ENGAGEMENT_STATS = 'ENGAGEMENT_STATS';

export const OMNI_SEARCH = 'OMNI_SEARCH';

export const SEGMENTS_LIST = 'SEGMENTS_LIST';

export const CANVA_AUTHORIZE_URL = 'CANVA_AUTHORIZE_URL';
export const CANVA_DESIGNS = 'CANVA_DESIGNS';
export const CANVA_EXPORT_JOB = 'CANVA_EXPORT_JOB';
export const CANVA_USER_PROFILE = 'CANVA_USER_PROFILE';

/**
 * @readonly
 * @enum {string}
 */
export const MutationKey = Object.freeze({
	SCHEDULING_CAMPAIGN: 'SCHEDULING_CAMPAIGN'
});
