import { getDateFromTimestamp, queryKey, type Callback, type Merge, type Timestamp } from '@segunosoftware/equinox';
import type { BannerTone } from '@shopify/polaris';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ACCOUNT, ACCOUNT_LANGUAGE, ACCOUNT_LANGUAGES } from './query-keys';
import type { APIError, Delete, Get, Post, Put } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type ReviewServiceType = 'NONE' | 'SEGUNO' | 'SHOPIFY' | 'JUDGEME' | 'STAMPED' | 'YOTPO' | 'JUNIP' | 'LOOX' | 'FERA';

export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';
export type ReviewStatus = 'ignored' | 'reviewed' | 'denied' | 'deferred';

export const ALL_INDUSTRIES = [
	'beauty',
	'clothing',
	'electronics',
	'furniture',
	'handcrafts',
	'jewelry',
	'painting',
	'photography',
	'restaurants',
	'groceries',
	'other_food_drink',
	'sports',
	'toys',
	'services',
	'virtual_services',
	'other',
	'do_not_know'
] as const;
export type Industry = (typeof ALL_INDUSTRIES)[number];

export const ALL_COMPANY_SIZES = ['one', 'less_than_five', 'less_than_ten', 'eleven_to_twenty_five', 'move_than_twenty_five'] as const;
export type CompanySize = (typeof ALL_COMPANY_SIZES)[number];

export type AccountSurvey = {
	contactName: string;
	contactEmail: string;
	industry: Industry;
	companySize: CompanySize;
};

export type ComplianceHold =
	| 'NONE'
	| 'SUBSCRIBER_IMPORT'
	| 'AWAITING_SURVEY'
	| 'PENDING_REVIEW'
	| 'AWAITING_CUSTOMER'
	| 'UNUSUAL_ACTIVITY'
	| 'UNTRUSTED_CONTENT';

export type CsmNote = {
	title: string;
	note: string;
	status: BannerTone;
	dismissedAt: Date;
};

export type DismissedContent = {
	onboardingDismissed: boolean;
	scheduleDemoDismissed: boolean;
	demoVideoDismissed: boolean;
	mailChimpSyncCompleteShowing: boolean;
	klaviyoSyncCompleteShowing: boolean;
	talkToSupportDismissed: boolean;
	importSubscribersDismissed: boolean;
	authenticateDomainDismissed: boolean;
	dynamicBannerSuiteAdDismissed: boolean;
	backfillCompleteShowing: boolean;
	cleanedSubscribersDismissed: boolean;
	newslettersVideoDismissed: boolean;
	automationsVideoDismissed: boolean;
	templatesVideoDismissed: boolean;
	settingsVideoDismissed: boolean;
	campaignsVideoDismissed: boolean;
	planningGuideDismissed: boolean;
	suiteCardDismissed: boolean;
};

export type Address = {
	companyName?: string;
	address1?: string;
	address2?: string;
	city?: string;
	province?: string;
	zip?: string;
	country?: string;
	phone?: string;
};

export type SendAddress = {
	fromName: string;
	fromLocalEmail: string;
	replyEmail: string;
};

export type DateFormat =
	| 'YEAR_MONTH_DAY'
	| 'MONTH_DAY_YEAR'
	| 'DAY_MONTH_YEAR'
	| 'SLASH_YEAR_MONTH_DAY'
	| 'SLASH_MONTH_DAY_YEAR'
	| 'SLASH_DAY_MONTH_YEAR'
	| 'DOTS_YEAR_MONTH_DAY'
	| 'DOTS_MONTH_DAY_YEAR'
	| 'DOTS_DAY_MONTH_YEAR'
	| 'LONG_MONTH_DAY_YEAR'
	| 'LONG_DAY_MONTH_YEAR'
	| 'SHORT_MONTH_DAY_YEAR'
	| 'SHORT_DAY_MONTH_YEAR'
	| 'WEEKDAY_LONG_MONTH_DAY_YEAR'
	| 'WEEKDAY_LONG_DAY_MONTH_YEAR'
	| 'WEEKDAY_LONG_MONTH_DAY'
	| 'WEEKDAY_LONG_DAY_MONTH';

export type TimeFormat = 'TWELVE_HOUR_LOWER' | 'TWELVE_HOUR' | 'TWENTY_FOUR_HOUR';

export type StandardFormats = {
	dateFormat: DateFormat;
	timeFormat: TimeFormat;
};

export type AccountSettings = {
	companyAddress: Address;
	sendingAddress: SendAddress;
	standardFormats: StandardFormats;
	recommendationCollectionId?: number;
	testRecipients: string;
	language: string;
};

export type OnboardingSteps = {
	subscribersImported: boolean;
	settingsReviewed: boolean;
	popupsSkipped: boolean;
};

export type BillingPlan = 'LEGACY' | 'STANDARD' | 'ADVANCED';

export type BillingPackageType = 'LEGACY' | 'STANDARD' | 'ADVANCED' | 'STANDARD_15' | 'STANDARD_25' | 'STANDARD_25_POPUPS';

export type FontFamily =
	| 'ARIAL'
	| 'ARIAL_BLACK'
	| 'BOOKMAN'
	| 'COMIC_SANS'
	| 'COURIER'
	| 'COURIER_NEW'
	| 'FUTURA'
	| 'GARAMOND'
	| 'GEORGIA'
	| 'HELVETICA'
	| 'IMPACT'
	| 'LUCIDA_CONSOLE'
	| 'LUCIDA_SANS'
	| 'OPEN_SANS'
	| 'PALATINO'
	| 'ROBOTO'
	| 'TAHOMA'
	| 'TIMES'
	| 'TIMES_NEW_ROMAN'
	| 'TREBUCHET'
	| 'VERDANA'
	| 'SYSTEM';

export type ButtonStyle = 'SOLID' | 'OUTLINE';

export type ButtonShape = 'SQUARE' | 'ROUNDED' | 'PILL';

export type NavigationLink = {
	id: string;
	name: string;
	url: string;
};

export type SocialNetwork =
	| 'INSTAGRAM'
	| 'FACEBOOK'
	| 'PINTEREST'
	| 'TWITTER'
	| 'SNAPCHAT'
	| 'TIKTOK'
	| 'YOUTUBE'
	| 'SPOTIFY'
	| 'LINKEDIN'
	| 'VIMEO'
	| 'TUMBLR'
	| 'WHATSAPP'
	| 'BLOG';

export type SocialMediaLink = {
	id: string;
	network: SocialNetwork;
	url: string;
};

export type MailLogo = {
	logoUrl?: string;
	imageCanvaId?: string;
	logoWidth: number;
};

export type MailSettings = {
	mailStructure: {
		spacing: 'NONE' | 'COMPACT' | 'COMFORTABLE';
	};
	headerStyle: 'NONE' | 'CENTERED_LOGO' | 'LOGO_WITH_SOCIAL';
	logo: MailLogo;
	fonts: {
		headingsFontFamily: FontFamily;
		headingsFontSize: number;
		menuFontFamily: FontFamily;
		buttonsFontFamily: FontFamily;
		fontFamily: FontFamily;
		fontSize: number;
		lineHeight: number;
		letterSpacing: number;
		productTitlesFontSize: number;
		capitalizeHeadings: boolean;
		capitalizeButtons: boolean;
		capitalizeMenuItems: boolean;
		capitalizeProductTitles: boolean;
		boldHeadings: boolean;
		boldPrimaryButtons: boolean;
		boldSecondaryButtons: boolean;
		boldMenuItems: boolean;
		boldProductTitles: boolean;
	};
	colors: {
		starRatingColor: string;
		bodyBackgroundColor: string;
		gutterBackgroundColor: string;
		preheaderBackgroundColor: string;
		headerBackgroundColor: string;
		footerBackgroundColor: string;
		headings: string;
		bodyText: string;
		saleText: string;
		primaryButtonBackground: string;
		primaryButtonText: string;
		secondaryButtonBackground: string;
		secondaryButtonText: string;
		smallButtonBackground: string;
		smallButtonText: string;
		links: string;
		divider: string;
		discountBackground: string;
		discountText: string;
		calloutBackground: string;
		calloutText: string;
		calloutAccent: string;
		calloutCode: string;
		calloutCodeBackground: string;
		calendarAccent: string;
		menuBackground: string;
		menuText: string;
		menuBorder: string;
		suggestedColors: string[];
	};
	buttons: {
		primaryButtonStyle: ButtonStyle;
		secondaryButtonStyle: ButtonStyle;
		smallButtonStyle: ButtonStyle;
		buttonShape: ButtonShape;
	};
	background: {
		url?: string;
		tile: boolean;
	};
	navigationLinks: NavigationLink[];
	socialLinks: SocialMediaLink[];
	preheaderText: string;
	policyText: string;
	showReplyToEmail: boolean;
	showCustomerServicePhone: boolean;
	showHeaderUnsubscribe: boolean;
	showMenu: boolean;
	showFooterSocials: boolean;
	hideSegunoBranding: boolean;
	hideUnsubscribeBorder: boolean;
};

export type KlaviyoSyncData = {
	siteId: string;
	apiKey: string;
	listIds: string[];
	isSyncComplete: boolean;
};

export type Account = {
	id: number;
	shop: string;
	domain: string;
	timezone: string;
	currency: string;
	primaryLocale: string;
	contactName: string;
	contactEmail: string;
	platformPlan: string;
	ownerName: string;
	ownerEmail: string;
	userHash: string;
	supportUserHash: string;
	phone: string;
	name: string;
	revenueTrackingId: string;
	billedSubscribers: number;
	freeTierCap: number;
	basePrice: number;
	monthlyPrice: number;
	paidMonthlyPrice: number;
	paidSubscriberLimit: number;
	billingStatus: AccountBillingStatus;
	complianceHoldStatus: ComplianceHold;
	accountSettings: AccountSettings;
	topLevelDomain: string;
	sendingDomain: string;
	sharedSendingDomain: boolean;
	allowSendingWhileWarming: boolean;
	csmNote: CsmNote;
	reviewServiceType: ReviewServiceType;
	mailSettings: MailSettings;
	reviewRating: number;
	reviewStatus: ReviewStatus;
	appPurchaseCredits: number;
	backfillComplete: boolean;
	backfillPercentComplete: number;
	listCleaningComplete: boolean;
	agreedToCleanList: boolean;
	storefrontProtected: boolean;
	dismissedContent: DismissedContent;
	onboardingSteps: OnboardingSteps;
	accountSurvey?: AccountSurvey;
	mailChimpListId?: string;
	mailChimpSyncComplete: boolean;
	mailChimpAuthenticated: boolean;
	klaviyoSyncData?: KlaviyoSyncData;
	complianceHold: boolean;
	abraConnected: boolean;
	isFacebookConnected: boolean;
	isInstagramConnected: boolean;
	isCanvaConnected: boolean;
	sendingDomainWarmed: boolean;
	shouldRecordScreen: boolean;
	paidFeaturesAvailable: boolean;
	advancedPaidFeaturesAvailable: boolean;
	sendingAvailable: boolean;
	billingPlan: BillingPlan;
	paidPlan: boolean;
	currentlyPaying: boolean;
	suspended: boolean;
	trialCompleted: boolean;
	trialRestarted: boolean;
	upgradedEarly: boolean;
	performingImport: boolean;
	createdAt: Date;
	updatedAt: Date;
	billingGracePeriodEndsAt?: Date;
	trialEndsAt?: Date;
	changelogLastViewedAt?: Date;
	shopCreatedAt?: Date;
	chargeablePlan: boolean;
	bisInventoryThreshold: number;
	postscriptAccessToken?: string;
	postscriptConnected: boolean;
	gaClientId?: string;
	billingPackageTypeToEstimate: Record<BillingPackageType, { monthlyPrice: number; subscriberLimit: number }>;
};

export type DehydratedAccount = Merge<
	Account,
	{
		createdAt: Timestamp;
		updatedAt: Timestamp;
		billingGracePeriodEndsAt?: Timestamp;
		trialEndsAt?: Timestamp;
		changelogLastViewedAt?: Timestamp;
		shopCreatedAt?: Timestamp;
	}
>;

export type Language = {
	language: string;
	translations: Record<string, any>;
};

const hydrateAccount = (account: DehydratedAccount): Account => {
	return {
		...account,
		createdAt: new Date(account.createdAt),
		updatedAt: new Date(account.updatedAt),
		billingGracePeriodEndsAt: getDateFromTimestamp(account.billingGracePeriodEndsAt),
		trialEndsAt: getDateFromTimestamp(account.trialEndsAt),
		changelogLastViewedAt: getDateFromTimestamp(account.changelogLastViewedAt),
		shopCreatedAt: getDateFromTimestamp(account.shopCreatedAt)
	};
};

export function useAccountFetch(load = false) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedAccount>;
	const {
		data: account,
		refetch: fetchAccount,
		isFetching: isLoading
	} = useQuery(queryKey(ACCOUNT), () => get('/auth/whoami').then(account => (account ? hydrateAccount(account) : undefined)), {
		enabled: load
	});
	return { account, fetchAccount, isLoading };
}

export function useAccount() {
	const { account } = useAccountFetch();

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

export function useSetAccount() {
	const client = useQueryClient();
	const setAccount = useCallback(
		(account: DehydratedAccount) => {
			const hydrated = hydrateAccount(account);
			client.setQueryData(queryKey(ACCOUNT), hydrated);
			return hydrated;
		},
		[client]
	);
	return setAccount;
}

export function useSaveSettings() {
	const { put } = useAuthenticatedFetch() as Put<AccountSettings, DehydratedAccount>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const { mutate: saveSettings, isLoading: isSavingSettings } = useMutation(
		(settings: AccountSettings) => put(`/account/settings`, settings),
		setAccountOption
	);

	return { saveSettings, isSavingSettings };
}

export function useAccountLanguages(enabled: boolean = true) {
	const { get } = useAuthenticatedFetch() as Get<Language[]>;
	const queryClient = useQueryClient();

	const {
		data,
		refetch: getAccountLanguages,
		isFetching: isLanguagesLoading
	} = useQuery(queryKey([ACCOUNT_LANGUAGES]), () => get('/account/languages'), { enabled });

	function invalidateAccountLanguages() {
		queryClient.invalidateQueries(queryKey(ACCOUNT_LANGUAGES));
	}

	return {
		languages: data ?? [],
		getAccountLanguages,
		isLanguagesLoading,
		invalidateAccountLanguages
	};
}

export function useAccountLanguage(language: string) {
	const { get, put, delete: del } = useAuthenticatedFetch() as Get<Language> & Put<Language, Language> & Delete<string>;
	const queryClient = useQueryClient();
	const { invalidateAccountLanguages } = useAccountLanguages();
	const languagePath = '/account/language';

	const { data, isFetching: isLanguageLoading } = useQuery(getQueryKey(language), () => get(`${languagePath}/${language}`), {
		enabled: Boolean(language)
	});

	const {
		mutate: saveLanguage,
		isLoading: isSavingLanguage,
		error: saveLanguageError
	} = useMutation((language: Language) => put(languagePath, language), {
		onSuccess: language => {
			queryClient.setQueryData(getQueryKey(language.language), language);
			invalidateAccountLanguages();
		}
	});

	const resetLanguage = (language: string) => saveLanguage({ language, translations: {} });

	const {
		mutate: deleteLanguage,
		isLoading: isDeletingLanguage,
		error: deleteLanguageError
	} = useMutation(language => del(`/account/delete-language/${language}`), {
		onSuccess: invalidateAccountLanguages
	});

	function getQueryKey(language: string) {
		return queryKey(ACCOUNT_LANGUAGE, language);
	}

	return {
		language: data ?? {},
		isLanguageLoading,
		saveLanguage,
		isSavingLanguage,
		saveLanguageError,
		resetLanguage,
		deleteLanguage,
		isDeletingLanguage,
		deleteLanguageError
	};
}

export function useCleanSubscribers() {
	const { post } = useAuthenticatedFetch() as Post<Pick<Account, 'agreedToCleanList'>, DehydratedAccount>;
	const setAccount = useSetAccount();
	const { mutate, isLoading } = useMutation((agreedToCleanList: boolean) => post('/account/agreed-to-clean-list', { agreedToCleanList }), {
		onSuccess: setAccount
	});

	return {
		setAgreedToCleanList: (agreedToCleanList: boolean) => mutate(agreedToCleanList),
		isLoading
	};
}

export function useReviewRating() {
	const { post } = useAuthenticatedFetch() as Post<any, DehydratedAccount>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const { mutate: setReviewRating, isLoading: isSetReviewRatingLoading } = useMutation(
		(reviewRating: number) => post('/account/review-rating', { rating: reviewRating }),
		setAccountOption
	);

	const { mutate: setReviewStatus, isLoading: isSetReviewStatusLoading } = useMutation(
		(reviewStatus: ReviewStatus) => post('/account/review-status', { reviewStatus }),
		setAccountOption
	);

	return {
		setReviewRating: (reviewRating: number) => setReviewRating(reviewRating),
		isSetReviewRatingLoading,
		setReviewStatus: (reviewStatus: ReviewStatus) => setReviewStatus(reviewStatus),
		isSetReviewStatusLoading
	};
}

export function useUpdateBisInventoryThreshold(onSuccess?: Callback<Account>) {
	const { post } = useAuthenticatedFetch() as Post<any, DehydratedAccount>;
	const setAccount = useSetAccount();

	const mutation = useMutation((threshold: number) => post('/account/back-in-stock:set-inventory-threshold', threshold).then(setAccount), {
		onSuccess: account => onSuccess && onSuccess(account)
	});

	return {
		updateBisInventoryThreshold: (threshold: number) => mutation.mutate(threshold),
		isUpdatingBisInventoryThreshold: mutation.isLoading,
		updateFailed: mutation.isError
	};
}

export function useAccountSurvey() {
	const { post } = useAuthenticatedFetch() as Post<AccountSurvey, DehydratedAccount>;
	const setAccount = useSetAccount();

	const { mutate: submitAccountSurvey, isLoading } = useMutation((survey: AccountSurvey) => post('/account/survey', survey), {
		onSuccess: setAccount
	});

	return {
		submitAccountSurvey,
		isLoading
	};
}

export type Survey = {
	name: string;
	email: string;
	businessAge?: string;
	shopNameChanged?: boolean;
	oldShopName?: string;
	oldShopUrl?: string;
	previousEsp?: string;
	mailchimpActive?: boolean;
	previousEspOther?: string;
	collectSubscribers: string[];
	collectSubscribersOther?: string;
	sendingFrequency?: string;
	sendingRecency?: string;
	lastOpenRate: number;
	deliverabilityIssues: string[];
	deliverabilityIssuesOther?: string;
	syncedToShopify?: boolean;
	expectedSyncDate: null;
	comments?: string;
};

export type SurveySubmission = {
	survey: Survey;
	file?: File;
};

export function useComplianceSurvey() {
	const { post } = useAuthenticatedFetch() as Post<FormData, DehydratedAccount>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const {
		mutate: submitSurvey,
		isLoading,
		error
	} = useMutation<DehydratedAccount, APIError, SurveySubmission>((data: SurveySubmission) => {
		const formData = new FormData();
		formData.append('survey', JSON.stringify(data.survey));
		if (data.file) {
			formData.append('file', data.file);
		}
		return post('/account/submit-survey', formData);
	}, setAccountOption);

	return {
		submitSurvey,
		isLoading,
		error
	};
}

export function useSetClientId() {
	type SetClientIdRequest = { clientId: string };
	const { post } = useAuthenticatedFetch() as Post<SetClientIdRequest, DehydratedAccount>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const { mutate: setClientId, isLoading } = useMutation(
		(clientId: string) => post('/account/set-client-id', { clientId }),
		setAccountOption
	);

	return {
		setClientId,
		isLoading
	};
}
