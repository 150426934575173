import { useEffect } from 'react';
import { useAccount } from '../hooks/useAccount';

const IS_PRODUCTION = import.meta.env.PROD;

export default function ScreenCapture() {
	const account = useAccount();

	useEffect(() => {
		if (IS_PRODUCTION && account?.shouldRecordScreen) {
			async function initiateReplay() {
				const { getCurrentHub, Replay } = await import('@sentry/react');
				const client = getCurrentHub()?.getClient();
				if (client && !client?.getIntegration(Replay) && client.addIntegration) {
					client.addIntegration(
						new Replay({
							maskAllText: false,
							maskAllInputs: false,
							blockAllMedia: false
						})
					);
				}
			}
			initiateReplay();
		}
	}, [account?.shouldRecordScreen]);

	return null;
}
